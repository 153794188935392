<template>
 <div :id="randomId">
  <v-runtime-template :template="itemTemplate"></v-runtime-template>
 </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import VRuntimeTemplate from "vue3-runtime-template";
import axios from "axios";
// <List apiEndpoint='/api/Banners/List' itemTemplate='<img :src="'/data/banners/' + a.image" alt="" />' > </List>
/*
 <DataList apiEndpoint='/api/album/picture_list?album_id=1'  style='margin:auto; position:relative;' 
 itemTemplate='<div style="width:50px"><button class="scroll-btn left" @click="scroll(-1)">&#10094;</button></div><ul class="mt-3 scrolling-wrapper"  @scroll="updateArrows()"><li class=" scrolling-item m-1" v-for="item in items" :key="item.id" ><router-link class="img" :to="`/portfolio/${item.id}`"><img :src="`/data/albums/1/${item.pic}`" class=" col-12"  style="height:150px; "/></router-link></li></ul><div style="width:50px"><button class="scroll-btn right" @click="scroll(1)">&#10095;</button></div>' > </DataList>
apiEndpoint
 @click="scroll(-1)"
 @scroll="updateArrows()"
  @click="scroll(1)"
*/
export default {
 name: "DataList",
 components: {
  VRuntimeTemplate,
 },
 props: {
  apiEndpoint: {
   type: String,
   required: true,
  },
  itemTemplate: {
   type: String,
   required: true,
  },
  settingString: {
   type: String,
   required: true,
  },
 },
 data() {
  const randomId = `id_${Math.random().toString(36).substr(2, 9)}`;

  return {
   randomId: randomId,
   items: null,
   settings: null,
  };
 },
 methods: {
  fetchItems() {
   axios
    .get(this.apiEndpoint)
    .then((response) => {
     if ("list" in response.data) this.items = response.data.list;
     else if (Array.isArray(response.data)) this.items = response.data;
     else this.items = [response.data]
     this.$nextTick(() => {
      this.updateArrows();
     });
    })
    .catch((error) => {
     console.error("Error fetching items:", error);
    });
  },
  scroll(direction) {
   var container = document.querySelector( "#" + this.randomId + " .scrolling-wrapper");
   var scrollAmount = 100; // 滾動量，您可以根據需要調整

   container.scrollLeft += scrollAmount * direction;
   this.updateArrows();
  },
  updateArrows() {
   var container = document.querySelector( "#" + this.randomId + " .scrolling-wrapper");
   var leftArrow = document.querySelector(
    "#" + this.randomId + " .scroll-btn.left"
   );
   var rightArrow = document.querySelector(
    "#" + this.randomId + " .scroll-btn.right"
   );

   if (leftArrow) {
    if (container.scrollLeft === 0) {
     leftArrow.classList.add("hidden");
    } else {
     leftArrow.classList.remove("hidden");
    }
   }

   if (rightArrow) {
    if (container.scrollLeft >= container.scrollWidth - container.clientWidth) {
     rightArrow.classList.add("hidden");
    } else {
     rightArrow.classList.remove("hidden");
    }
   }
  },
 },
 mounted() {
  this.$nextTick(() => {
   if (this.apiEndpoint && this.apiEndpoint != "") this.fetchItems();
  });
 },
};
</script>
