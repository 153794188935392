<template>
 <FullCalendar :id="randomId" :options="calendarOptions" />
</template>

<script>
/* eslint-disable vue/no-unused-components */
import VRuntimeTemplate from "vue3-runtime-template";
import Swal from "sweetalert2";
import axios from "axios";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
// eslint-disable-next-line
import allLocales from "@fullcalendar/core/locales-all";

export default {
 name: "FCalendar",
 components: {
  VRuntimeTemplate,
  FullCalendar,
 },
 props: {
  apiEndpoint: {
   type: String,
   required: true,
  },
  itemTemplate: {
   type: String,
   required: true,
  },
  itemFire: {
   type: String,
   required: true,
  },
  settingString: {
   type: String,
   required: true,
  },
 },
 data() {
  const randomId = `id_${Math.random().toString(36).substr(2, 9)}`;

  return {
   randomId: randomId,
   calendarOptions: {
    plugins: [dayGridPlugin],
    initialView: "dayGridMonth",
   },
  };
 },
 methods: {
  swalFire(obj, _then) {
   var v = this;
   if (obj.preConfirmFormID) {
    obj.preConfirm = this.preCheckForm(obj.preConfirmFormID);
   }
   Swal.fire(obj).then((result) => {
    if (obj.isConfirmed && result.isConfirmed) obj.isConfirmed(result);
    if (_then) _then(result, v, window);
   });
  },
  fetchItems() {
   axios
    .get(this.apiEndpoint)
    .then((response) => {
     this.calendarOptions.events = response.data;
    })
    .catch((error) => {
     console.error("Error fetching items:", error);
    });
  },
 },
 mounted() {
  this.$nextTick(() => {
    if(window.DEBUG)console.log("calendar");
   if (this.settingString) {
    this.calendarOptions = JSON.parse(this.settingString);
    this.calendarOptions.plugins = [dayGridPlugin];
    this.calendarOptions.locales = allLocales;
   }
   if (this.apiEndpoint && this.apiEndpoint != "") this.fetchItems();
  });
 },
};
</script>
