<template>
 <div :id="randomId">
  <v-runtime-template :template="itemTemplate"></v-runtime-template>
 </div>
</template>
 
 <script>
/* eslint-disable vue/no-unused-components */
import VRuntimeTemplate from "vue3-runtime-template";
import axios from "axios";

export default {
 name: "DataForm",
 components: {
  VRuntimeTemplate,
 },
 props: {
  apiEndpoint: {
   type: String,
   required: true,
  },
  itemTemplate: {
   type: String,
   required: true,
  },
  settingString: {
   type: String,
   required: true,
  },
 },
 data() {
  const randomId = `id_${Math.random().toString(36).substr(2, 9)}`;

  return {
   randomId: randomId,
   data: {},
  };
 },
 methods: {
  submitFormJson() {
   axios
    .post(this.apiEndpoint, "json=" + JSON.stringify(this.data), {
     headers: {
      "Content-Type": "application/x-www-form-urlencoded",
     },
    })
    .then((response) => {
      if(window.DEBUG)console.log(response);
    })
    .catch((error) => {
     console.error("Error fetching items:", error);
    });
  },
 },
};
</script>
 